import register from '../util/init'
import Flickity from 'flickity'
import 'flickity/css/flickity.css'
// import {} from '../util/tools'
import {PHONE_MAX} from './constants'
import {ajaxPageLoad} from './site'

class initPage {
  constructor (container) {
    this.container = container
    this.windowWidth = null
    // init home feature flickity
    this.initHomeFeatureFlickity()

    return {
      resize: (w, h) => {
        this.windowWidth = w
      },
      scroll: (top, left) => {}
    }
  }
  /**
  * create a flickity carousel for the home feature
  *
  */
  initHomeFeatureFlickity () {
    const element = this.container.querySelector('.home-features .items')
    const children = [...(element.children)]
    if (children.length > 1) {
      const options = {
        freeScrollFriction: 0.075,
        dragThreshold: 80,
        selectedAttraction: 0.025,
        friction: 0.28,
        cellAlign: 'left',
        // contain: true,
        pageDots: true,
        prevNextButtons: false,
        on: {
          ready: function () {
            //
          },
          settle: (index) => {
            //
          },
          staticClick: function (e, pointer, cellElement, cellIndex) {
            // UPDATE now optionally goes to url
            let url = cellElement.getAttribute('data-static-click')
            if (url) {
              if (!cellElement.classList.contains('external')) {
                // internal
                e.preventDefault()
                ajaxPageLoad.load(url, null)
              } else {
                // external
                let win = window.open(url, '_blank')
                win.focus()
              }
            }
          }
        }
      }
      const flickity = new Flickity(element, options)
    }
  }
}
register('.home-content', initPage)
