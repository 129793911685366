import docReady from 'doc-ready'
import Flickity from 'flickity'
import 'flickity/css/flickity.css'

import AjaxLoader, {isLocalLink} from '../util/ajax_util'
import {addHandler, removeHandler} from '../util/scroll_resize'
import {initContainers, teardownContainers} from '../util/init'
import {updateLinkState} from '../util/link_state'
import {lazyLoad, scrollPointTrigger, toggle, cutPaste, relativeScroll} from '../util/tools'
import {PHONE_MAX, TABLET_PORTRAIT_MAX} from './constants'
import ScrollBalance from '../util/scrollbalance'
// import {bindQuestionForm} from './question'

export let pageClasses
export let ajaxPageLoad
let init
const transitionendPrefixed = ['transitionend', 'webkitTransitionEnd']

docReady(() => {
  pageClasses = [...document.body.classList]
  let setup = new SiteSetup()
  init = new SiteInit()
  window.setTimeout(() => {
    // first up reveal (js only)
    document.body.style.opacity = null
    document.documentElement.classList.add('state-initialised')
  }, 100)
})

class SiteSetup {
  constructor () {
    this.windowWidth = null
    this.windowTop = null
    this.everything = document.querySelector('.everything')
    this.primaryNavigation = document.querySelector('#header nav.primary')
    // have a standard class set when close to top
    scrollPointTrigger(80, {
      className: 'past-top',
      applyClass: document.body
    })
    // init scroll fixed/unfixed for
    scrollPointTrigger(this.primaryNavigation, {
      className: 'navigation-fixed',
      applyClass: document.body,
      setWidth: true
    })
    // load all pages with ajax
    this.initAjaxPages()
    // init email-subscribe/ask a question functionality
    this.initPockets(document.querySelector('#shirt'))
    // bind ask a question form
    // bindQuestionForm(document.getElementById('pocket-question-form'))
    // init phone nav toggle
    document.querySelector('#header .burger').addEventListener('click', (e) => {
      e.preventDefault()
      toggle(document.body, 'navigation-visible', this.navigationCallback, this)
    })
    // fade colour of header
    // document.querySelectorAll('#header .grape-background').forEach((el) => {
    //   relativeScroll(el, {
    //     factor: 0.055,
    //     isDecimal: true,
    //     property: 'opacity'
    //   })
    // })
    // resize the logo
    // relativeScroll(document.querySelector('#header .brand svg'), {
    //   factor: 0.15,
    //   property: 'height',
    //   min: 2,
    //   max: 2.5,
    //   unit: 'em',
    //   reverse: true
    // })
    addHandler({
      resize: (w, h) => {
        this.windowWidth = w
      },
      scroll: (t, l) => {
        this.windowTop = t
      }
    }, true)
  }
  /**
  * all site transitions from page to page using ajax
  * TODO edit speeds pause (possibly run it as a single swipe?)
  *      scroll positions/ above or below nav height.
  *
  */
  initAjaxPages () {
    const curtain = document.querySelector('#curtain')
    const content = document.querySelector('#content')
    const breadcrumbs = document.querySelector('#shirt .breadcrumbs')
    // init ajax loader
    ajaxPageLoad = new AjaxLoader('chfo', (newBody, state) => {
      let runSwitchPage = false
      let runRemoveStaleTransitionClasses = false
      const removeStaleTransitionClasses = (e) => {
        runRemoveStaleTransitionClasses = true
        // ie 11 doesn't remove multiple classes
        let staleClasses = ['curtain-in', 'curtain-out', 'prevent-multiple-ajax']
        for (let c in staleClasses) {
          document.body.classList.remove(staleClasses[c])
        }
        // strip out the (transition out) event listener
        transitionendPrefixed.forEach(transitionend =>
          curtain.removeEventListener(transitionend, removeStaleTransitionClasses, true)
        )
      }
      const switchPage = (e) => {
        runSwitchPage = true
        // strip out the custom page classes
        pageClasses.forEach((oldClass) => {
          document.body.classList.remove(oldClass)
        })
        // drop in the new classes
        const newClasses = [...newBody.classList]
        newClasses.forEach((newClass) => {
          document.body.classList.add(newClass)
        })
        // reassign for the next unload
        pageClasses = newClasses
        // drop in the new content
        const newArticle = newBody.querySelector('#content > article')
        content.innerHTML = ''
        content.appendChild(newArticle)
        // drop in the new breadcrumbs
        const newBreadcrumbs = newBody.querySelector('#shirt .breadcrumbs ul')
        breadcrumbs.innerHTML = ''
        breadcrumbs.appendChild(newBreadcrumbs)
        // bind new ajax links
        this.bindAjaxLinks(newArticle)
        // rebind all the breadcrumb links too
        this.bindAjaxLinks(newBreadcrumbs)
        if (!state) {
          // reset the scroll position - unless we're going back
          const scrollReset = this.windowWidth > PHONE_MAX ? 102 : 0
          document.documentElement.scrollTop = scrollReset
          document.body.scrollTop = scrollReset
        }
        // run a new init, this handles all general + page specific js
        init = new SiteInit()
        // add a delay - for dramatic effect
        window.setTimeout(() => {
          // if we passed a (curtain-in) transition in
          if (document.body.classList.contains('curtain-in')) {
            // strip out the (transition in) event listener
            transitionendPrefixed.forEach(transitionend =>
              curtain.removeEventListener(transitionend, switchPage, true)
            )
            // apply transition class (out)
            document.body.classList.add('curtain-out')
            // once the transition (out) has ended remove remnant classes
            transitionendPrefixed.forEach((transitionend) => {
              curtain.addEventListener(transitionend, removeStaleTransitionClasses, true)
            })
            // fallback
            window.setTimeout(() => {
              if (!runRemoveStaleTransitionClasses) {
                removeStaleTransitionClasses()
              }
            }, 1500)
          } else {
            // assime that we're on phone with the navigation-visible
            document.body.classList.remove('navigation-visible')
            this.everything.style.top = null
            removeStaleTransitionClasses()
          }
        }, 800)
      }
      // RUN THE PAGE SWITCH
      // apply transition class (in)
      if (!document.body.classList.contains('navigation-visible')) {
        document.body.classList.add('curtain-in')
        // once the transition (in) has ended swith/load the new page
        transitionendPrefixed.forEach((transitionend) => {
          curtain.addEventListener(transitionend, switchPage, true)
        })
        // fallback
        window.setTimeout(() => {
          if (!runSwitchPage) {
            switchPage()
          }
        }, 1500)
      } else {
        // no curtain transition
        // skip this transition for navigation-visible (phone)
        switchPage()
      }
    }, {
      onBeforeLoad: (url) => {
        // strip out the resize/scroll listeners for the current page
        teardownContainers(document.querySelectorAll('#content > article'))
        // likewise strip the handler off the siteinit functions
        init.teardown.forEach((handler) => {
          removeHandler(handler)
          if (handler.teardown) handler.teardown()
        })
      }
    })

    // first call bind entire DOM
    this.bindAjaxLinks(document.body)
  }
  /**
  * all site transitions from page to page using ajax
  * TODO consider scroll links within page
  * @function bindAjaxLinks
  * @param {HtmlElement} selector
  *
  */
  bindAjaxLinks (container) {
    container.querySelectorAll('a[href]:not([target="_blank"]):not([href*="events"]):not([href*="donations"]):not([href*="project/"]):not([href*="donate"]):not([href*="payment"]):not([href*="request-receipt"]):not([href*="subscribe"])').forEach((a) => {
      const url = a.getAttribute('href')
      if (url && isLocalLink(a)) {
        a.addEventListener('click', (e) => {
          const modifier = e.shiftKey || e.ctrlKey || e.metaKey
          if (!modifier && !a.classList.contains('current')) {
            e.preventDefault()
            if (!document.body.classList.contains('prevent-multiple-ajax')) {
              document.body.classList.add('prevent-multiple-ajax')
              const scrollReset = 102
              const wait = this.windowTop < scrollReset && this.windowWidth > PHONE_MAX ? 400 : 0
              if (wait !== 0) {
                window.scroll({
                  top: scrollReset,
                  left: 0,
                  behavior: 'smooth'
                })
              }
              window.setTimeout(() => {
                ajaxPageLoad.load(url, null)
              }, wait)
            }
          }
        })
      }
    })
  }
  /**
  * navigationCallback - when I toggle the nav, lock everything else into positions
  * this way we can prevent the body scrolling
  *
  * @param {bool} isExpanded
  * @param {Class} that, this(SiteSetup)
  */
  navigationCallback (isVisible, that) {
    if (isVisible) {
      that.everything.style.top = `-${that.windowTop}px`
    } else {
      const resetTop = Math.abs(parseInt(that.everything.style.top))
      document.documentElement.scrollTop = resetTop
      document.body.scrollTop = resetTop
      that.everything.style.top = null
    }
  }
  /**
  * setup the pocket interaction (forms in #shirt)
  * @function initPockets
  * @param {HtmlElement} selector
  *
  */
  initPockets (shirt) {
    let resettingScroll = false
    const pockets = ['pocket-newsletter', 'pocket-question']
    const bindTriggers = (p) => {
      document.querySelectorAll(`.${pockets[p]}-toggle, .${pockets[p]} .close`).forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
          e.preventDefault()
          // close any other pockets that are open,
          // then run the toggle
          let wait = 0
          for (let i = 0; i < pockets.length; i++) {
            if (p !== i && shirt.classList.contains(`${pockets[i]}-open`)) {
              wait = 450
              shirt.classList.remove(`${pockets[i]}-open`)
            }
          }
          // if we clicked the bottom ask a question scroll up
          if (this.windowWidth > PHONE_MAX && this.windowTop > 200) {
            resettingScroll = true
            window.scroll({
              top: 102,
              left: 0,
              behavior: 'smooth'
            })
          }
          window.setTimeout(() => {
            toggle(shirt, `${pockets[p]}-open`, toggleCallback)
          }, wait)
        })
      })
    }
    const toggleCallback = (isVisible) => {
      window.setTimeout(() => {
        resettingScroll = false
      }, 1000)
    }
    // set up the toggles
    for (let i = 0; i < pockets.length; i++) {
      bindTriggers(i)
    }
    addHandler({
      resize: (w, h) => {},
      scroll: (t, l) => {
        // if we scroll down, strip out any dropdown
        if (this.windowWidth > PHONE_MAX && t > 300 && !resettingScroll) {
          shirt.classList.remove('pocket-newsletter-open', 'pocket-question-open')
        }
      }
    }, true)
  }
}

class SiteInit {
  // initialises newly loaded content
  // recallable for ajax loaded content
  constructor () {
    // establish a new teardown for ajax page unload
    this.teardown = []
    // call page specific js
    const current = document.querySelectorAll('#content > article')
    initContainers(current)
    // update all links 'current' state
    updateLinkState(document.querySelectorAll('#header, #content'))
    // load lazy images
    current[0].querySelectorAll('.lazyload').forEach((el) => {
      lazyLoad(el)
    })
    // on phone slip the news previews into a flickity
    this.initNewsPreviewFlickity(current[0])
    // init scroll balance for requested instances
    this.initScrollbalance(current[0].querySelectorAll('[data-request-scrollbalance]'))
    // ...
    this.initFooterScroll(document.querySelectorAll('#skirt, #footer'))
    // shift elements on phone into a new position
    current[0].querySelectorAll('[data-cut-paste]').forEach((el) => {
      cutPaste(el)
    })
  }
  /**
  * create a flickity carousel for the news previews
  * @function initNewsPreviewFlickity
  * @param {HtmlElement} selector
  *
  */
  initNewsPreviewFlickity (current) {
    let flickity = null
    const element = current.querySelector('.news-preview .items, .more-stories .items')
    const options = {
      freeScrollFriction: 0.075,
      dragThreshold: 80,
      selectedAttraction: 0.025,
      friction: 0.28,
      cellAlign: 'left',
      // contain: true,
      pageDots: true,
      prevNextButtons: false,
      on: {
        ready: function () {
          //
        },
        settle: (index) => {
          //
        }
      }
    }
    //
    const handler = addHandler({
      resize: (w, h) => {
        if (w > PHONE_MAX && flickity) {
          // desktop
          flickity.destroy()
          flickity = null
        }
        if (w <= PHONE_MAX && element && !flickity) {
          // phone
          flickity = new Flickity(element, options)
        }
      },
      scroll: (t, l) => {}
    }, true)

    // add to teardown (for ajax page unload)
    this.teardown.push(handler)
  }
  /**
  * init all requested scrollbalance instances
  * @function initScrollbalance
  * @param {HtmlElement} selector
  *
  */
  initScrollbalance (elements) {
    let scrollbalanceInstances
    if (elements.length > 0) {
      scrollbalanceInstances = new ScrollBalance(elements, {
        minwidth: TABLET_PORTRAIT_MAX
      })
      scrollbalanceInstances.bind()

      const handler = addHandler({
        teardown: () => {
          scrollbalanceInstances.unbind()
        }
      })

      // add to teardown (for ajax page unload)
      this.teardown.push(handler)
    }
  }
  /**
  * scroll delay some of the footer elements
  * @function initFooterScroll
  * @param {NodeList} selectors
  *
  */
  initFooterScroll (elements) {
    // shift the footer, skirt and footer 'f'
    elements.forEach((element) => {
      const rs = relativeScroll(element, {
        factor: 0.25,
        property: 'transform',
        unit: 'px',
        min: 1,
        max: 300,
        reverse: true,
        triggerPoint: 1,
        offset: () => { return element.offsetTop }
      })
      // add to teardown (for ajax page unload)
      this.teardown.push(rs)
    })
  }
}
