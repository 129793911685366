import register from '../../util/init'
import {videoEmbed} from '../../util/video'

class initPage {
  constructor (container) {
    this.container = container
    // setup youtube/vimeo video embeds
    container.querySelectorAll('.video-player .player').forEach((player) => {
      videoEmbed(player, player.getAttribute('data-video_url'))
    })
  }
}
register('.news-article-content', initPage)
