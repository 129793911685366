// vimeo
function getVimeoId (url) {
  var match = url.match(/vimeo\.com\/(\d+)/)
  return match ? match[1] : ''
}

function vimeoPlayer (el, url) {
  const id = getVimeoId(url)

  const play = document.createElement('a')
  play.classList.add('play')
  el.appendChild(play)

  // get a still image
  // $.getJSON('https://www.vimeo.com/api/v2/video/' + id + '.json?callback=?',
  //   {format: 'json'}, (data) => {
  //     el.style.backgroundImage = `url(${data[0].thumbnail_large})`
  //   })

  play.addEventListener('click', () => {
    const width = el.width
    const height = el.height
    const player = document.createElement('iframe')
    player.setAttribute('src', `https://player.vimeo.com/video/${id}?api=1&autoplay=1&title=0&byline=0&portrait=0`)
    player.setAttribute('width', width)
    player.setAttribute('height', height)
    player.setAttribute('frameborder', 0)
    player.setAttribute('webkitallowfullscreen', 'webkitallowfullscreen')
    player.setAttribute('mozallowfullscreen', 'mozallowfullscreen')
    player.setAttribute('allowfullscreen', 'allowfullscreen')
    el.appendChild(player)
  })
}
// youtube
function getYoutubeId (url) {
  var match = url.match(/(?:v=|youtu.be\/)([A-z0-9\-]+)/)
  return match ? match[1] : ''
}

function youtubePlayer (el, url) {
  const id = getYoutubeId(url)

  const play = document.createElement('a')
  play.classList.add('play')
  el.appendChild(play)

  // get a still image
  // could posibly use api? But need key
  // https://www.googleapis.com/youtube/v3/videos?key=YOUR_API_KEY&part=snippet&id=T0Jqdjbed40"
  // no guarantee this image exists
  // el.style.backgroundImage = 'url(https://i.ytimg.com/vi/' + id + '/maxresdefault.jpg)'
  // el.style.backgroundImage = 'url(https://i.ytimg.com/vi/' + id + '/hqdefault.jpg)'

  play.addEventListener('click', () => {
    const width = el.width
    const height = el.height
    const player = document.createElement('iframe')
    player.setAttribute('src', `https://www.youtube.com/embed/${id}?api=1&autoplay=1&title=0&byline=0&portrait=0&rel=0`)
    player.setAttribute('width', width)
    player.setAttribute('height', height)
    player.setAttribute('frameborder', 0)
    player.setAttribute('webkitallowfullscreen', 'webkitallowfullscreen')
    player.setAttribute('mozallowfullscreen', 'mozallowfullscreen')
    player.setAttribute('allowfullscreen', 'allowfullscreen')
    el.appendChild(player)
  })
}

export function videoEmbed (el, url) {
  // vimeo
  let vimeoId = getVimeoId(url)
  if (vimeoId) {
    vimeoPlayer(el, url)
    el.classList.add('vimeo-video')
    return
  }
  let youtubeId = getYoutubeId(url)
  if (youtubeId) {
    youtubePlayer(el, url)
    el.classList.add('youtube-video')
  }
}
