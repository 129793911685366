import register from '../../util/init'
import InfScr from '../../util/infscr'
import { ajaxUrl } from '../../util/ajax_util'
import {lazyLoad} from '../../util/tools'
import {addHandler} from '../../util/scroll_resize'

class initPage {
  constructor (container) {
    this.container = container
    // infinite scroll page items
    this.infscrInstance = this.initInfscr()
  }
  /**
 * setup an infinite scrolling page
 *
 * @method initInfscr
 * @return {function instance} InfScr
 */
  initInfscr () {
    const infscrInstance = InfScr(this.container.querySelector('.page'), {
      contentSelector: '.page-items',
      nextSelector: '.next-page',
      autoOffset: 1500,
      // cache: false,
      // varyOnCookies: [window.CURRENT_RANDOM_SEED],
      get_url: (link) => {
        return ajaxUrl(link.getAttribute('href'))
      },
      beforeLoad: function () {},
      onLoad: function (newContent, container) {
        // load lazy images
        container.querySelectorAll('.lazyload').forEach((el) => {
          lazyLoad(el)
        })
      }
    })
    addHandler({
      resize: (w, h) => {
        infscrInstance.resize(w, h)
      },
      scroll: (t, l) => {
        infscrInstance.scroll(t, l)
      }
    }, true)

    return infscrInstance
  }
}
register('.donorstories-content', initPage)
