import register from '../../util/init'
import Flickity from 'flickity'
import 'flickity/css/flickity.css'

class initPage {
  constructor (container) {
    this.container = container

    // init home feature flickity
    this.initProfileFlickity()
  }
  /**
  * create a flickity carousel for the profiles
  *
  */
  initProfileFlickity () {
    const element = this.container.querySelector('.images .items')
    const children = [...(element.children)]
    if (children.length > 1) {
      const options = {
        freeScrollFriction: 0.075,
        dragThreshold: 80,
        selectedAttraction: 0.025,
        friction: 0.28,
        cellAlign: 'left',
        // contain: true,
        pageDots: true,
        prevNextButtons: false,
        on: {
          ready: function () {
            //
          },
          settle: (index) => {
            //
          },
          staticClick: function (e, pointer, cellElement, cellIndex) {
            let next = cellIndex === (this.getCellElements().length - 1) ? 0 : cellIndex + 1
            this.select(next)
          }
        }
      }
      const flickity = new Flickity(element, options)
    }
  }
}
register('.donorstories-donor-content', initPage)
